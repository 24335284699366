<template>
  <div>
    <el-form :model="form"
             :rules="rules"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="6">
          <el-form-item label="选择系统"
                        prop="is_edu_user">
            <el-select v-model="form.is_edu_user"
                       style="width:100%"
                       placeholder="请选择">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.text"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="开始时间">
            <el-date-picker v-model="form.start_time"
                            type="datetime"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择开始时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.end_time"
                            type="datetime"
                            style="width:100%"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择截止时间" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button type="primary"
                     @click="search">查询</el-button>

        </el-col>
      </el-row>
    </el-form>

    <el-table border
              :stripe="true"
              ref="multipleTable"
              highlight-current-row
              :data="tableData"
              style="width: 100%">

      <el-table-column prop="user_id"
                       align="center"
                       label="用户ID" />
      <el-table-column prop="api_url"
                       align="center"
                       label="api_url" />

      <el-table-column prop="request_data"
                       align="center"
                       label="请求数据" />
      <el-table-column prop="is_edu_user"
                       align="center"
                       label="系统">
        <template slot-scope="scope">
          <span>{{ scope.row.is_edu_user == 0?'学生端':"教师端" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time"
                       align="center"
                       label="开始时间" />
      <!-- 
      <el-table-column align="center"
                       label="操作">
        <template slot-scope="{row}">
          <el-button type="primary"
                     @click="showForm(row)">录入详情</el-button>
        </template>
      </el-table-column> -->
    </el-table>

    <pagination ref="pagination"
                :get-data="initData"
                :isInit="false"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import { now_month_time, nowDateTime, frontNowDay,frontDay } from '@/utils/date.js'

export default {
  name: "apiLog",
  data () {
    return {
      tableData: [],
      options: [{ value: 0, text: "学生端日志" },
      { value: 1, text: "教师端日志" }],
      form: {
        is_edu_user: '',
        start_time: frontDay(7),
        end_time: nowDateTime(),
      },
      page: 1,
      size: 10,
      totalElements: 0,
      rules: {
        is_edu_user: [
          { required: true, message: '请选择', trigger: 'change' }
        ],
      }
    }
  },
  mounted () {

  },
  methods: {

    search () {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.$refs.pagination.toFirstPage()
        } else {
          this.$message.error('请选择所查看的系统');
        }
      });
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/tc_power/api_log_list',
        method: 'get',
        params: form
      }).then(res => {
        console.log('res', res)
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    showForm (row) {
      let form = {
        start_time: this.form.start_time,
        end_time: this.form.end_time,
        edu_user_id: row.edu_user_id
      }

      this.$router.push('/topicAdmin/usertopicList?form=' + JSON.stringify(form))
    },

  }
}
</script>

<style lang="scss" scoped>
</style>